.cookieBox {
    background-color: #030403;
    opacity: .8;
    position: fixed;
    bottom: 0;
    width: 21%;
    font-size: 14px;
    color: '#414141';
    padding: 10px;
    text-align: 'left';
    z-Index: 1000;
}

.fa-cookie {
    font-size: 40px;
    float: left;
}

.box-doc {
    color: #000;
    padding-bottom: 1%;
    padding-top: 0%;
    margin-top: 0%;
}

.box-doc-title {
  padding-top: 3%; padding-bottom: 0%; 
}


@media only screen and (max-width: 768px) { 
    .cookieBox {
        width: 100%;
        font-size: 14px;
        color: '#414141';
    }   
}

@media only screen and (max-width: 420px) { 
    .cookieBox {
        width: 100%;
        font-size: 14px;
        color: '#414141';
    }
}