.footer {
    /* background-color: #282c34; 
    background-color: #64c683; */
    background-color: transparent;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    margin-bottom: 3%;
  }
  
  .footer-content {
    background-color: #64c683;
    text-align: left;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .footer-title {
    color: #388361;
    font-weight: 700;
    text-align: left;
  }

  .footer-description  {
    width: 100%;
  }
  
  .footer-description h3 {
    margin-bottom: 7px;
    font-size: 24px;
  }
  
  .footer-description p {
    max-width: 350px;
    line-height: 1.6;
  }

  .footer-socials {
    text-align: left;
  }
  
  .footer-socials h4 {
    padding-left: 0px;
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .social-icons .icon {
    color: #fff;
    font-size: 24px;
    margin: 0 13px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #50680d; /* Changer cette couleur en fonction de la couleur du réseau social */
  }

  .footer-bottom {
    padding: 30px 10;
  }

  .footer-copy {
    font-size: 12px;
    margin-top: 1%;
    font-weight: 600;
  }


  @media only screen and (max-width: 768px) {

    .footer {
        padding: 10px;
        font-size: 15px;
    }

    .social-icons .icon {
        margin: 0 10px;
        transition: color 0.3s;
      }

      .footer-socials h4 {
        font-size: 17px;
      }

  }

  