.prod {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #099e51;
    border: 2px solid #099e51;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.offline {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #cf1212;
    border: 2px solid #cf1212;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.developer {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #aa6900;
    border: 2px solid #aa6900;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.input {
    width: 100%;
}


.input-box {
    width: 100%;
}

.desc-input {
    margin-top: 1%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #545454;
    line-height: 21px;
}