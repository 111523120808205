.bg-documentation {
    background-color: #f5fcf7;
}

.box-doc {
    color: #000;
    padding-bottom: 1%;
    padding-top: 0%;
    margin-top: 0%;
}

.box-doc-title {
  padding-top: 3%; padding-bottom: 0%; 
}

@media only screen and (max-width: 420px) { 
    .box-doc-title {
        padding-top: 15%; padding-bottom: 2%;
    }
    .box-doc {
        margin: 3%;
    }
}