.box {
    overflow: hidden;
    background: #fff;
    box-shadow: 2px 0 25px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    z-index: 1;
    text-align: center;
}

.div-details {
  text-align: left;
}

.label {
  text-align: left;
  width: 100%;
  font-weight: 550;
}

.box-form {
	width: 25%;
}

input {
  width: 100%;
}

.connexion {
  text-decoration: none;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-weight: 600;
  font-size: 17px;
  text-transform: none;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #fff;
  background: #099e51;
  border: 2px solid #fff;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}

.box-subtitle {
  text-align: center;
}

.inscription {
  text-decoration: none;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-weight: 600;
  font-size: 17px;
  text-transform: none;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #099e51;
  background: #fff;
  border: 2px solid #099e51;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}

.message-form {
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) { 
  .bb {
    margin-top: 15%;
  }
  
  .box-form {
	width: 90%;
  }

  .connexion {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 13px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #099e51;
    border: 2px solid #fff;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }
  
  .inscription {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 13px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 40px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #099e51;
    background: #fff;
    border: 2px solid #099e51;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }

}


@media only screen and (max-width: 425px) {
    /* For mobile phones: */
	
	.connexion {
      border: 2px solid #fff;
	    font-size: 13px;
    }
	
}

@media only screen and (max-width: 320px) {
    /* For mobile phones: */
	
		.connexion {
		  border: 2px solid #fff;
		  font-size: 13px;
		}
		h1 {
			  /*color: #05b365;*/
			  font-size: 20px;
			  font-weight: 900;
		}
}