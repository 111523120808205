.progress-bar-container {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 25px;
    margin: 20px auto;
    height: 30px;
    width: 80%;
    border: 1px solid #ddd;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 25px;
    text-align: center;
    line-height: 30px;
    color: white;
    font-weight: bold;
  }