.App-Home {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;   
	overflow-y: hidden;
}

.dash {
	background: rgb(243, 248, 247);
	height: 96%;
	overflow: hidden;
	overflow-y: visible;
}

.box-campagne {
    height: 68vh;
    background: #fff;
	overflow: hidden;
    box-shadow: 2px 0 25px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    z-index: 1;
    text-align: center;
}

.disabled {
    text-align: left;
    width: 100%;
    text-decoration: none;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 600;
    font-size: 17px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 0px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.info {
    font-size: 11px;
}

.title {
    margin-top: 1%;
}

.open-project {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 17px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 40px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #099e51;
    border: 2px solid #099e51;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
}
.box{
    text-align: left;
    height: 65vh;
    padding: 3px 5px 3px 5px;
    margin-right: 2%;
}

.div-details {
    height: 95%;
    background-color: rgb(243, 248, 247);
    overflow-y: scroll;
}

.description {
    font-size: 25px;
    font-weight: 510;
    color: #414141;
}

.datepush {
    font-size: 13.5px;
    color: #414141;
    font-weight: 500;
}

.target {
    color: rgb(6, 92, 32);
    font-weight: 500;
    font-size: 13px;
}

a {
    text-decoration: underline;
    color: black;
}

/****  MODAL ****/
.modal-custom .modal-dialog {
  max-width: 75%; /* Ajustez cette valeur selon vos besoins */
  margin: auto;
}
/****** FIN MODAL ****/


@media only screen and (max-width: 768px) { 


    .box-campagne {
        height: 70vh;
        min-width: 90vw;
        overflow: hidden;
        background: #fff;
        box-shadow: 2px 0 25px 0 rgba(68, 88, 144, 0.12);
        transition: all 0.3s ease-in-out;
        border-radius: 15px;
        z-index: 1;
        text-align: center;
    }
    
    .title {
        margin-top: 10%;
    }
    .box {
        padding: 6%;
        /*margin: 2%;*/
    }
    .description {
        font-size: 13px;
    }
    
    .datepush {
        font-size: 12px;
    }
    
    .target {
        font-size: 12px;
    }

}


