.box-form-contact {
    width: 50%;
}

.input, label {
    font-size: 15px;
}

.form-info {
    font-size: 16px;
}

/* Personnalisation du bouton OK de SweetAlert */
.swal2-confirm {
    background-color: #28a745; /* Vert */
    color: white; /* Texte blanc */
    border: none; /* Aucune bordure */
    border-radius: 5px; /* Bords arrondis */
    padding: 10px 20px; /* Espacement interne */
    font-weight: bold; /* Texte en gras */
}

.swal2-confirm:hover {
    background-color: #218838; /* Vert plus foncé au survol */
}


@media only screen and (max-width: 768px) { 
 
    .box-form-contact {
        width: 100%;
    }

    .input, label {
        font-size: 13px;
        font-weight: 500;
    }

    .form-info {
        font-size: 13px;
        font-weight: 550;
    }
}