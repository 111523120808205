.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:  #feffff;
    display: flex;
    color: #05b365;
    padding: 1rem; 
  }
  
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #05b365;
    text-decoration: none;
  }

  img {
    width: 40px;
    border-radius: 9px;
  }
  
  .navbar-center .nav-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-center .nav-links li {
    margin-right: 1rem;
  }
  
  .navbar-center .nav-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
  }
  

  .navbar-right .user-icon {
    color: #304233;
    text-decoration: none;
    margin-left: 1.3rem; font-size: 1.2rem;
    position: relative;
  }

  .navbar-right .cart-icon {
    color: #304233;
    text-decoration: none;
    margin-left: 1rem; font-size: 1.2rem;
    position: relative;
  }

  .go-to-console {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: medium;
    color: #304233;
    font-weight: 550;
  }

  .go-to-mail {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: medium;
    color: #304233;
    font-weight: 550;
  }
  
  .navbar-right .cart-count {
    background-color: #f44336;
    color: #fff;
    border-radius: 50%;
    padding: 0.2rem 0.4rem;
    font-size: 0.5rem;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }