body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 3.5rem;
 --first-color: hsl(28, 88%, 62%);
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 0%, 75%);
 --body-color: hsl(0, 0%, 100%);
 --body-font: "Montserrat", sans-serif;
 --second-font: "Dancing Script", cursive;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
}

body {
 /* background-color: var(--body-color); */
 color: var(--text-color);
 font-family: var(--body-font);
}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}

	.assistant {
	  width: 32px;
	}

	.loading {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: calc(10px + 2vmin);
		color: white;
	}
	
	.btn-form {
		position: relative;
        width: 100%;
        text-decoration: none;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 1px;
        display: inline-block;
        padding: 15px 40px;
        border-radius: 25px;
        transition: 0.3s;
        line-height: 1;
        color: #099e51;
        background: #fff;
		border: 1px solid #099e51;
        animation-delay: 0.3s;
        cursor: pointer;
        overflow: hidden;
	}
	
	.btn-generate0 {
		position: relative;
        width: 100%;
        text-decoration: none;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 1px;
        display: inline-block;
        padding: 15px 40px;
        border-radius: 25px;
        transition: 0.3s;
        line-height: 1;
        color: #fff;
        background: #414141;
		border: 1px solid #099e51;
        animation-delay: 0.3s;
        cursor: pointer;
        overflow: hidden;
	}
	
	.btn-generate0::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
		height: 100%;
		width: 1%; /* Set to 25% for this example */
        background: #099e51;
        z-index: 0;
        transition: width 0.3s ease;
    }
	
	.btn-generate25 {
		position: relative;
        width: 100%;
        text-decoration: none;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 1px;
        display: inline-block;
        padding: 15px 40px;
        border-radius: 25px;
        transition: 0.3s;
        line-height: 1;
        color: #fff;
        background: #414141;
		border: 1px solid #099e51;
        animation-delay: 0.3s;
        cursor: pointer;
        overflow: hidden;
	}
	
	.btn-generate25::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
		height: 100%;
		width: 25%; /* Set to 25% for this example */
        background: #099e51;
        z-index: 0;
        transition: width 0.3s ease;
    }
	
    .btn-generate25 span {
        position: relative;
        z-index: 1;
    }
	
	.btn-generate50 {
		position: relative;
        width: 100%;
        text-decoration: none;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 1px;
        display: inline-block;
        padding: 15px 40px;
        border-radius: 25px;
        transition: 0.3s;
        line-height: 1;
        color: #fff;
        background: #414141;
		border: 1px solid #099e51;
        animation-delay: 0.3s;
        cursor: pointer;
        overflow: hidden;
	}
	
	.btn-generate50::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
		height: 100%;
		width: 50%; /* Set to 25% for this example */
        background: #099e51;
        z-index: 0;
        transition: width 0.3s ease;
    }
	
    .btn-generate50 span {
        position: relative;
        z-index: 1;
    }
	
	.btn-generate75 {
		position: relative;
        width: 100%;
        text-decoration: none;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
        letter-spacing: 1px;
        display: inline-block;
        padding: 15px 40px;
        border-radius: 25px;
        transition: 0.3s;
        line-height: 1;
        color: #fff;
        background: #414141;
		border: 1px solid #099e51;
        animation-delay: 0.3s;
        cursor: pointer;
        overflow: hidden;
	}
	
	.btn-generate75::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
		height: 100%;
		width: 75%; /* Set to 25% for this example */
        background: #099e51;
        z-index: 0;
        transition: width 0.3s ease;
    }
	
    .btn-generate75 span {
        position: relative;
        z-index: 1;
    }



/* -----  Loading PAGE BEGIN ------  */

/* ---
 * Je veux que le chargeur couvre la totalité de la page lors de son chargement,
 * je vais donc définir la largeur et la hauteur à 100%, puis définir la position à absolue et zéro en haut et à gauche.
 * J'ajoute également une couleur de fond.
 *
  ---
  */

  .loader-wrapper {
    width: 100%;
    height: 110vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f3f8f5;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #325e3a;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  }
  
  /* ---
   * Le code original utilise 50% de haut pour centrer le chargeur.
   * Je vais le supprimer et utiliser le flex layout pour centrer le cube loader à la place.
   *
    ---
   */
  .loader {
      border: 12px solid #f3f3f3;
      border-radius: 50%;
      border-top: 12px solid #325e3a;
      width: 70px;
      height: 70px;
      animation: spin 1s linear infinite;
  }
  
  .loader-inner {
      vertical-align: top;
      display: inline-block;
      width: 100%;
      background-color: #fff;
      animation: loader-inner 30s infinite ease;
  }
  
  @keyframes spin {
      100% {
          transform: rotate(360deg);
      }
  }
  
  /* -----  Loading PAGE END------  */